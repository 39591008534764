import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-270033f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "project-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContactInformation = _resolveComponent("ContactInformation")!

  return (_openBlock(), _createElementBlock("div", {
    class: "project",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDialog && _ctx.openDialog(...args)))
  }, [
    _createElementVNode("img", {
      class: "image",
      src: _ctx.project.attributes.imageUrl,
      alt: _ctx.project.attributes.imageCaption
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.project.attributes.title), 1)
    ]),
    (_ctx.project.attributes.contact)
      ? (_openBlock(), _createBlock(_component_ContactInformation, {
          key: 0,
          contact: _ctx.project.attributes.contact
        }, null, 8, ["contact"]))
      : _createCommentVNode("", true)
  ]))
}