import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Selector = _resolveComponent("Selector")!

  return (_openBlock(), _createBlock(_component_Selector, {
    attribute: _ctx.dateAttribute,
    onUpdate: _cache[0] || (_cache[0] = (n) => _ctx.updateFilter(_ctx.attribute.id.toString(), n)),
    right: true
  }, null, 8, ["attribute"]))
}