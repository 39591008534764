import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57b3b4b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "cardWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapComponent = _resolveComponent("MapComponent")!
  const _component_InformationComponent = _resolveComponent("InformationComponent")!
  const _component_TabComponent = _resolveComponent("TabComponent")!
  const _component_TabsComponent = _resolveComponent("TabsComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabsComponent, null, {
      default: _withCtx((scope) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
          return (_openBlock(), _createBlock(_component_TabComponent, {
            key: tab?.id,
            title: tab?.attributes.title,
            tabindex: index,
            activeTab: scope?.activeTab
          }, {
            map: _withCtx(() => [
              (scope.activeTab === 0)
                ? (_openBlock(), _createBlock(_component_MapComponent, {
                    key: 0,
                    src: "https://guben.elie.de/?Map/layerIds=02886f50-7bd5-46d3-a763-974213df3431,111222356,111222357,111222358,111222359,111222360,111222364,111222348&visibility=true,true,true,true,true,true,true,true&transparency=0,0,0,0,0,0,0,0"
                  }))
                : _createCommentVNode("", true),
              (scope.activeTab === 1)
                ? (_openBlock(), _createBlock(_component_MapComponent, {
                    key: 1,
                    src: "https://guben.elie.de/?Map/layerIds=02886f50-7bd5-46d3-a763-974213df3431,111222349,111222366,111222365,111222368&visibility=true,true,true,true,true&transparency=0,0,0,0,0"
                  }))
                : _createCommentVNode("", true),
              (scope.activeTab === 2)
                ? (_openBlock(), _createBlock(_component_MapComponent, {
                    key: 2,
                    src: "https://guben.elie.de/?Map/layerIds=02886f50-7bd5-46d3-a763-974213df3431,111222367,111222362,111222363,111222361&visibility=true,true,true,true,true&transparency=0,0,0,0,0"
                  }))
                : _createCommentVNode("", true),
              (scope.activeTab === 3)
                ? (_openBlock(), _createBlock(_component_MapComponent, {
                    key: 3,
                    src: "https://guben.elie.de/?Map/layerIds=02886f50-7bd5-46d3-a763-974213df3431,111222355,111222350,111222341&visibility=true,true,true,true&transparency=0,0,0,0"
                  }))
                : _createCommentVNode("", true),
              (scope.activeTab === 4)
                ? (_openBlock(), _createBlock(_component_MapComponent, {
                    key: 4,
                    src: "https://guben.elie.de/?Map/layerIds=02886f50-7bd5-46d3-a763-974213df3431,211222351,111222351,111222352,111222354,111222354&visibility=true,true,true,true,true,true&transparency=0,0,0,0,0,0"
                  }))
                : _createCommentVNode("", true)
            ]),
            info: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_InformationComponent, {
                  cards: tab?.attributes?.cards
                }, null, 8, ["cards"])
              ])
            ]),
            _: 2
          }, 1032, ["title", "tabindex", "activeTab"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}