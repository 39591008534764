import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b6fdec9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "iframe-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      class: "map-iframe",
      style: {"overflow":"hidden","height":"100%","width":"100%"},
      src: $setup.props.src,
      height: "100%",
      width: "100%"
    }, null, 8, _hoisted_2)
  ]))
}